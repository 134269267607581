import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import emailjs from 'emailjs-com';

Modal.setAppElement('#root');
function ContactForm() {
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [Phone, setPhone] = useState('');
  const [Text, setText] = useState('');
  const [Creator, setCreator] = useState('');
  const [NameError, setNameError] = useState({ errText: '' });
  const [EmailError, setEmailError] = useState({ errText: '' });
  const [TextError, setTextError] = useState({});
  const [ModalIsOpen, setModalIsOpen] = useState(false);
  const [SubmittedName, setSubmittedName] = useState('');

  useEffect(() => {
    if (Name.trim().length > 0) {
      setSubmittedName(Name);
    }
  }, [Name]);

  const resetForm = () => {
    setEmail('');
    setPhone('');
    setText('');
    setCreator('');
    resetName();
  };

  const resetName = () => {
    setTimeout(() => {
      setName('');
    }, 3000);
  };

  const Validate = () => {
    const NameError = {};
    const EmailError = {};
    const TextError = {};
    let isValid = true;

    if (Name.trim().length < 1) {
      NameError.errText = '*Name Field Required';
      isValid = false;
    }
    if (Text.trim().length < 1) {
      TextError.errText = '*Message Field Required';
      isValid = false;
    }
    if (Email.trim().length < 1) {
      EmailError.errText = '*Email Field Required';
      isValid = false;
      setNameError(NameError);
      setEmailError(EmailError);
      setTextError(TextError);
      return isValid;
    }
    if (!Email.includes('@') || !Email.includes('.')) {
      EmailError.errText = '*Invalid Email Field';
      isValid = false;
    }

    setNameError(NameError);
    setEmailError(EmailError);
    setTextError(TextError);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = Validate();

    if (isValid) {
      emailjs
        .send(
          'service_t3krizr',
          'template_arka9dv',
          {
            to_name: 'FullScope',
            sender_name: Name,
            name: Name,
            email: Email,
            phone: Phone,
            message: Text,
            creator: Creator,
            'reply-to': Email,
          },
          'J39k_i-cyrEWLADv4'
        )
        .then(
          (result) => {
            console.log('Email sent:', result.text);
            setSubmittedName(Name);
            setModalIsOpen(true);
            resetForm();
          },
          (error) => {
            console.error('Error sending email:', error.text);
          }
        );
    }
  };

  return (
    <>
      <Modal isOpen={ModalIsOpen} onRequestClose={() => setModalIsOpen(false)} className="popup-send">
        <div className="block-popup">
          <h4>{SubmittedName},</h4>
          <h4>Your message has been sent successfully</h4>
          <h4>Thank you.</h4>
        </div>
        <span onClick={() => setModalIsOpen(false)} className="close-btn"></span>
      </Modal>
      <div id="form-wrapper">
        <div id="form-inner">
          <div id="MainResult"></div>
          <div id="MainContent">
            <form id="MyContactForm" name="MyContactForm" method="post" onSubmit={handleSubmit}>
              <p className="name">
                <input type="text" name="name" id="name" placeholder="Your Name*" value={Name} onChange={(e) => setName(e.target.value)} />
                <label htmlFor="name" id="nameLb">
                  <span className="error">{NameError.errText}</span>
                </label>
              </p>
              <p>
                <input type="text" name="email" id="email" placeholder="Your Email*" value={Email} onChange={(e) => setEmail(e.target.value)} />
                <label htmlFor="email" id="emailLb">
                  <span className="error error1">{EmailError.errText}</span>
                </label>
              </p>
              <p>
                <input type="text" name="phone" id="phone" placeholder="Your Telephone" value={Phone} onChange={(e) => setPhone(e.target.value)} />
                <label htmlFor="phone" id="phoneLb"></label>
              </p>
              <p className="textarea">
                <textarea name="message" id="message" placeholder="Your Message*" rows="4" value={Text} onChange={(e) => setText(e.target.value)}></textarea>
                <label htmlFor="message" id="messageLb">
                  <span className="error">{TextError.errText}</span>
                </label>
              </p>
              <p>
                <input type="text" name="creator" id="creator" placeholder="Creator Code" value={Creator} onChange={(e) => setCreator(e.target.value)} />
                <label htmlFor="creator" id="creatorLb"></label>
              </p>
              <div className="clearfix"></div>
              <div className="text-center">
                <input type="submit" className="contact-btn btn-move t2" value="Send Message" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactForm;
